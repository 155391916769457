<template>
  <v-dialog
    v-model="dialog"
    max-width="500px"
    transition="dialog-transition"
  >
    <template v-slot:activator="{ on: d }">
      <v-tooltip left>
        <span>Columns</span>
        <template v-slot:activator="{ on: tt }">
          <v-btn
            fab
            color="color3 color3Text--text"
            small
            v-on="{ ...tt, ...d }"
          >
            <v-icon small>fas fa-columns</v-icon>
          </v-btn>
        </template>
      </v-tooltip>
    </template>
    <v-card>
      <v-toolbar color="color1 color1Text--text">
        <v-toolbar-title>Columns</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn color="color1Text" text icon @click.stop="dialog = false">
          <v-icon>fas fa-times</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text>
        <v-row dense v-for="column in columns" :key="column.value">
          <v-col cols="6">
            <v-checkbox
              :label="column.header"
              v-model="column.show"
              hide-details
              color="success"
              class="mt-0"
            ></v-checkbox>
          </v-col>
          <v-col cols="6">
            <setting-toggle
              :dataId="division.id"
              :disabled="tournament.locked"
              :propName="`public-${column.value}`"
              :isDivisionProp="true"
              :jProp="true"
              iClass="shrink mt-0"
              :small='$vuetify.breakpoint.xsOnly'
              label="Publish"
            ></setting-toggle>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex'
import SettingToggle from '@/components/Tournament/QuickSettings/SettingToggle.vue'

export default {
  props: ['columns', 'division'],
  data () {
    return {
      dialog: false
    }
  },
  computed: {
    ...mapGetters(['tournament', 'theme'])
  },
  components: {
    SettingToggle
  }
}
</script>

<style>

</style>
